<template>
  <div class="view-home parent-page for-parent for-parent-block position-relative">
    <div class="question-specialist show-modal" v-if="showModalQuestion">
      <div class="close-btn" @click="showModalQuestion = false">✖</div>
      <div class="item">
        {{ $t('questionForSpecialist') }}
      </div>
      <div class="item">
        <el-input :placeholder="$t('parentsPlaceholderName')" v-model="questionSpecialist.name">

        </el-input>
      </div>
      <div class="item">
        <el-input :placeholder="$t('parentsPlaceholderEmail')" v-model="questionSpecialist.email">

        </el-input>
      </div>
      <div class="item">
        <el-input :placeholder="$t('parentsPlaceholderAge')" v-model="questionSpecialist.age" @input="validateNumber">

        </el-input>
      </div>
      <el-select class="item" v-model="questionSpecialist.region_id" :placeholder="$t('parentsPlaceholderRegion')">
        <el-option
            v-for="item in regions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <div class="item">
        <el-input type="textarea" v-model="questionSpecialist.answer"
                  :placeholder="$t('parentsPlaceholderQuestion')"
                  :rows="5">

        </el-input>
      </div>
      <el-checkbox v-model="agreeWithTerms">{{ $t('agreeWithTerms') }}</el-checkbox>
      <el-button round @click="sendQuestionSpecialist">{{ $t('profile.notes.btn-send') }}</el-button>
    </div>

    <div class="question-specialist" v-if="!showModalQuestion" @click="showModalQuestion=true">
      <div class="question-specialist-title">
        {{ $t('questionForSpecialist') }}
      </div>
    </div>

    <div class="parents-main">
      <div class="container d-flex flex-column">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
            <li class="breadcrumbs-item">{{ $t('for-parents.subtitle') }}</li>
          </ul>
        </nav>
        <div class="row">
          <div class="col-lg-6">
            <div class="parents-main-left">
              <el-image
                  class="main-img"
                  style="width: 100%; height: 100%;"
                  src="/images/parents-main-img-1.png"
                  fit="cover"></el-image>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="parents-main-right">
              <h3>{{ $t('for-parents.subtitle') }}</h3>
              <p>
                {{ $t('learnMoreAboutProfessions') }}
              </p>
              <div class="main-img main-img-small">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/parents-main-img-2.png"
                    fit="contain"></el-image>
              </div>
              <div class="parents-mobile-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/parents-main-img-1.png"
                    fit="contain"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="definition">
      <div class="container">
        <div class="title">
          {{ $t('faq.question-1') }}
        </div>
        <p class="text">
          {{ $t('forParentsQabilet') }}
        </p>

      </div>

    </section>

    <section class="news-main" id="parentsList">
      <div class="container news-main-desktop">
        <div class="section-title" style="margin-top: 0">
          {{ $t('forParentsText1') }}
        </div>
        <div class="subtitle">
          {{ $t('forParentsText2') }}
        </div>
        <div class="row">
          <div class="col-lg-6">
            <router-link :to="{ name: 'parents-list', params: { id: parentsList[0].id }}">
              <div class="news-left-img">

                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[0].image_path"
                    fit="fit"></el-image>
              </div>
              <div class="heading">
                news
              </div>
              <div class="news-left-title truncate-title">
                {{ this.parentsList[0][`title_${$i18n.locale}`] || this.parentsList[0].title_ru}}
              </div>
              <div class="news-left-text truncate-text">
                {{ this.parentsList[0][`description_${$i18n.locale}`] || this.parentsList[0].description_ru}}
              </div>
            </router-link>
            <router-link to="/news" class="news-left-link">
              {{ $t('showMore') }}
            </router-link>
          </div>
          <div class="col-lg-6">
            <router-link  :to="{ name: 'parents-list', params: { id: parentsList[1].id }}" class="news-right-item"  v-if="this.parentsList[1]">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[1].image_path"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title truncate-title">
                  {{ this.parentsList[1][`title_${$i18n.locale}`] || this.parentsList[1].title_ru }}
                </div>
                <div class="news-item-left-text truncate-text">
                  {{ this.parentsList[1][`description_${$i18n.locale}`] || this.parentsList[1].description_ru}}
                </div>
              </div>
            </router-link>
            <router-link  :to="{ name: 'parents-list', params: { id: parentsList[2].id }}" class="news-right-item" v-if="this.parentsList[2]">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[2].image_path"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title truncate-title">
                  {{ this.parentsList[2][`title_${$i18n.locale}`] || this.parentsList[2].title_ru }}
                </div>
                <div class="news-item-left-text truncate-text">
                  {{ this.parentsList[2][`description_${$i18n.locale}`] || this.parentsList[2].description_ru}}
                </div>
              </div>
            </router-link>
            <router-link :to="{ name: 'parents-list', params: { id: parentsList[3].id }}" class="news-right-item" v-if="this.parentsList[3]">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="this.parentsList[3].image_path"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title truncate-title">
                  {{ this.parentsList[3][`title_${$i18n.locale}`] || this.parentsList[3].title_ru}}
                </div>
                <div class="news-item-left-text truncate-text">
                  {{ this.parentsList[3][`description_${$i18n.locale}`] || this.parentsList[3].description_ru}}
                </div>
              </div>
            </router-link>
          </div>

        </div>
      </div>
    </section>
    <section class="get-test">
      <div class="container get-test-img">
        <div class="title">
          {{ $t('for-teachers.about-testing-title') }}
        </div>
        <div class="test-steps">
          <div class="test-step">
            <div class="test-step-num">
              1
            </div>
            <div class="test-step-text">
              {{ $t('for-parents.faq-1') }}
            </div>
          </div>
          <div class="test-step">
            <div class="test-step-num">
              2
            </div>
            <div class="test-step-text">
              {{ $t('for-parents.faq-2') }}
            </div>
          </div>
          <div class="test-step">
            <div class="test-step-num">
              3
            </div>
            <div class="test-step-text">
              {{ $t('for-parents.faq-3') }}
            </div>
          </div>
        </div>
        <div class="test-consultation">
          <p>
            {{ $t('for-parents.faq-4') }}
          </p>
          <p style="font-weight: 700; color: #1090CB">
            {{ $t('for-members.about-tests-2') }}
          </p>
        </div>
        <div style="margin: 40px 0">
          <router-link class="regional-centers-link" to="/centers">
            {{ $t('for-teachers.address-btn') }}
          </router-link>
          <router-link class="reg-link" :to="regLink">
            {{ $t('for-parents.signup-btn') }}
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import {Carousel, Slide} from 'vue-carousel';
import Vue from "vue";
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      agreeWithTerms: false,
      questionSpecialist: {
        name: '',
        email: '',
        age: '',
        region_id: '',
        answer: '',
      },
      regLink: localStorage.getItem('token') ? "/profile" : "/signup-parents",
      selectRegion: "",
      showModalQuestion: false,
      perPageNews: 1,
      newsSlides: [
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
      ],
      regions:[
        {value: 1, label: "Астана", },
        {value: 2, label: "Алматы", },
        {value: 3, label: "Шымкент", },
        {value: 4, label: "Конаев", },
      ],
      parentsList: [],
    }
  },
  methods: {
    validateNumber(value) {
      this.questionSpecialist.age = value.replace(/\D/g, "");
    },
    validateForm() {
      const { name, email, age, region_id, answer } = this.questionSpecialist;

      if (!name || !email || !age || !region_id || !answer) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Все поля должны быть заполнены",
          type: 'error'
        })
        return false;
      }

      if (isNaN(age)) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Поле age должно быть числом",
          type: 'error'
        })

        return false;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Поле email должно быть корректным email адресом",
          type: 'error'
        })
        return false;
      }

      return true;
    },
    sendQuestionSpecialist() {

      if (!this.validateForm()) return

      if (!this.agreeWithTerms) {
        return Vue.toastr({
          message: 'Error',
          description: "Примите условия",
          type: 'error'
        })
      }

      this.$http.post(window.API_ROOT + '/api/questions-psychologist', {
        name: this.questionSpecialist.name,
        email: this.questionSpecialist.email,
        age: this.questionSpecialist.age,
        region_id: this.questionSpecialist.region_id,
        text: this.questionSpecialist.answer,
      }).then(res => {
        console.log("res", res)
        Vue.toastr({
          message: 'Успех',
          description: "Вопрос успешно отправлен!",
          type: 'success'
        })
        this.questionSpecialist = { name: '', email: '', age: '', region_id: '', answer: '', }
      }).catch(err => {
        console.log("err", err)
      })
    },
    signup() {
      var url = this.$router.resolve({name: 'signup-parent', query: {'role': 'parent'}})
      window.open(url.href);
    }
  },
  mounted() {
    if (window.innerWidth < 992) {
      this.perPageNews = 2
    }

    if (window.innerWidth < 500) {
      this.perPageNews = 1
    }

    this.$http.get(window.API_ROOT + '/api/references/regions')
        .then((res) => {
          this.regions = res.body.data;
        })

    this.$http.get(window.API_ROOT + '/api/parents/list')
        .then(res => {
          this.parentsList = res.body.data
          this.parentsList.sort( (a, b) => {
            // сортирую, чтобы вначале шли свежие новости
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
          })
        })
  }


}

</script>

<style>

.truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parents-mobile-img {
  display: none;
}

.news-main-mobile,
.mobile-line-top,
.carousel-mobile

{
  display: none;
}

.question-specialist {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 20px;
  background-color: #2073a0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 5;
}

.question-specialist-title {
  color: #FFF;
  cursor: pointer;
}

.question-specialist .item {
  width: 290px;
  color: #fff;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #fff;
}

.question-specialist .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #fff;
}

.main-img img {
  object-position: left center;
  border-radius: 20px;
}

.main-img-small {
  max-width: 290px;
  max-height: 301px;
}

@media screen and (max-width: 1000px) {
  .get-test-img::after {
    background-image: none;
  }
}

@media screen and (max-width: 992px) {

  .parents-mobile-img {
    display: block;
  }

  .parents-mobile-img img {
    border-radius: 20px;
  }

  .parents-main-left {
    display: none;
  }

  .main-img {
    display: none;
  }

  .parents-main-right {
    padding-top: 50px;
  }

  .parents-mobile-img {
    margin-bottom: -50px;
    padding-top: 30px;
    padding-left: 30px;
    position: relative;
  }

  .parents-mobile-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 90%;
    background: #1090CB;
    border-radius: 10px;
  }

  .definition {
    background-image: none;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .definition .text {
    width: 100%;
  }

  .questions-list {
    margin-top: 50px;
  }

  .get-test {
    margin-top: 30px;
  }

  .get-test .test-steps {
    flex-direction: column;
  }

  .test-steps .test-step {
    border-top: 1px solid #FFF;
    padding-top: 20px;
    width: 100%;
  }

  .test-step .test-step-num {
    font-size: 24px;
  }

  .test-step .test-step-text {
    font-size: 16px;
  }

  .get-test::after {
    background-image: none;
  }

  .get-test-img::after {
    background-image: none;
  }

  .test-consultation {
    width: 100%;
    padding: 20px;
    padding-top: 120px;
  }

  .test-consultation::before {
    top: 20px;
  }

  .regional-centers-link {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .reg-link {
    display: inline-block;
    width: 100%;
    margin-left: 0 !important;
    margin-top: 30px;
    text-align: center;
  }

  .news-main-mobile,
  .mobile-line-top,
  .carousel-mobile

  {
    display: block;
  }

  .news-main-mobile .section-title {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .carousel-mobile-item {
    padding-right: 20px;
  }

  .carousel-mobile-item .date {
    font-weight: 400;
    font-size: 14px;
    color: #9497A1;
    margin: 20px 0 12px 0;
  }

  .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
  }

  .carousel-mobile-item .more-btn {
    font-weight: 600;
    font-size: 16px;
    color: #185CFF;
    margin: 12px 0;
    position: relative;
    display: inline-block;
    padding-right: 30px;
  }

  .carousel-mobile-item .more-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/images/icon_arrow_right.svg");
  }

  .news-left-link {
    margin-bottom: 30px;
    display: inline-block;
  }

  .parent-page .news-main-desktop {

  }

  .news-item-left-text.truncate-text {
    -webkit-line-clamp: 2;
  }

}

</style>
